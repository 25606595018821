import {Helmet} from "react-helmet";
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Nav,Bot,Section,Text,Space,Icon,LoadingD, Photo } from '../Symbols.js'

export default (props)=>{
    const [eventData,setEventData] = useState(null)
    useEffect(() => {
        fetch("https://raw.githubusercontent.com/designatuci/data/main/events.json")
            .then((res) => res.json())
            .then((data) => {
                setEventData(data.events)
            })
    }, []);
    return <>
        <Helmet>
            <title>Design-a-thons – Design at UCI</title>
        </Helmet>
        <Nav/>
        <Section className="center short">
            <div className="wait dx scale flex row">
                <Icon w="64" h="64" src="designathon-logo.svg"/>
                <Space w="32" block/>
                {/* <Text size="S" className="bold color wait dx scale">The Design at UCI</Text> */}
                <Text size="XL" className="bold color black">Design-a-thon at UCI</Text>
            </div>
            <Space h="16"/>
            <div className="split2 textAlignLeft slim">
                <div className="flex left slim spaceChildrenSmall">
                    <Text className="color gray">A large-scale event where teams work to design a prototype product within a few days.</Text>
                    <Text className="color gray">To get involved, <Link to="/join/" className="link">join us</Link> to get notifications about when the next applications come out!</Text>
                </div>
                <div className="flex left slim spaceChildrenSmall">
                    <Text className="color gray">Our Design-a-thon is held once per year, and is open to everyone. Bring your team or form one during the event, and compete to create the best design centered around the prompt. Additionally, you can attend workshops hosted during the event to get extra help and inspiration from industry professionals.</Text>
                </div>
            </div>
        </Section>
        <Section className="center fill black" style={{background:"url(/static/photo/pulse.jpg) center/cover no-repeat"}}>
            <Link to="/designathon22/" className="flex spaceChildren">
                <Space h="96"/>
                <Text className="bold">Design-a-thon 2022</Text>
                <Text size="XXL" className="bold">Impact 2022</Text>
                <Text className="bold">January 28-30</Text>
                <Space h="32"/>
                <a className="button">
                    <Text className="color blue pagelink">Event Page</Text>
                </a>
            </Link>
        </Section>
        <Section className="short" style={{background:"linear-gradient(0deg,var(--white) 50%,var(--silver) 150%"}}>
            <div className="split2">
                <div className="flex left top spaceChildrenSmall">
                    <Text size="XL" className="bold">Design-a-thon 2021</Text>
                    <Space h="16"/>
                    <Text size="S" className="color blue" style={{marginBottom:"8px"}}>Theme</Text>
                    <Text className="color gray">Mindfulness & Productivity</Text>
                    <Text size="S" className="color blue" style={{marginBottom:"8px"}}>Participants</Text>
                    <Text className="color gray">100+</Text>
                </div>
                <div className="card L fill white shadow" style={{minHeight:"256px",padding:"16px"}}>
                    <a className="relative fill white button shadow" href="/static/designathon2020/index.html" target="_blank">
                        <Text className="pagelink color blue">Event Page</Text>
                    </a>
                    <Photo className="background" src="designathon2020thumb.svg"/>
                </div>
            </div>
            <Text size="L">Awarded Entries</Text>
            <div className="split2">
                <div className="card shadow flex spaceChildrenSmall">
                    <Text className="color blue" style={{alignSelft:"flex-start"}}>Best Overall</Text>
                    <div className="card" style={{width:"100%"}}>
                        <Photo src="img-motivator.svg" className="shadow" style={{minHeight:"256px"}}></Photo>
                    </div>
                    <Text size="L">Motivator</Text>
                    <Text className="color gray">Created by Allison Yick, Jonathan Lum, and Stephanie Chang</Text>
                    <a href="https://www.figma.com/file/h5bJ9LHNDm1K3PYy0Ck1wk/UCI-Designathon-–-Team-396D84-Copy?node-id=85%3A0" target="noreferer">
                        <Text className="pagelink color blue">View prototype</Text>
                    </a>
                </div>
                <div className="card shadow flex spaceChildrenSmall">
                    <Text className="color blue" style={{alignSelft:"flex-start"}}>Honorable Mention</Text>
                    <div className="card" style={{width:"100%"}}>
                        <Photo src="img-orin.svg" className="shadow" style={{minHeight:"256px"}}></Photo>
                    </div>
                    <Text size="L">Orin</Text>
                    <Text className="color gray">Created by Isha Godara</Text>
                    <a href="https://www.figma.com/file/uYMb1cJIA0JCn2twIbbIUE/Orin-Copy" target="noreferer">
                        <Text className="pagelink color blue">View prototype</Text>
                    </a>
                </div>
            </div>
        </Section>
        <Bot />
    </>
}

function formatDate(date) {
    let time = new Date(date)
    let str = ""
    str += mapDay(time.getDay()) + ", "
    str += mapMonth(time.getMonth()) + " "
    str += time.getDate() + " at "
    str += getHour(time.getHours()) + ":"
    str += getMinutes(time.getMinutes()) + " "
    str += getPeriod(time.getHours())
    str += " your time"
    return str
}
function formatCardDate(date) {
    let now = new Date()
    let time = new Date(date)
    let diff = now - time
    if (diff < 0) {
        diff = Math.abs(diff)
        const days = Math.floor(diff/(1000*60*60*24))
        if (days == 0) {
            return `Very soon`
        } else if (days <= 1) {
            return `In ${days} day`
        } else if (days <= 7) {
            return `In ${days} days`
        } else {
            let weeks = Math.round(days/7)
            if (weeks == 1) {
                return `In ${weeks} week`
            } else {
                return `In ${weeks} weeks`
            }
        }
    } else {
        const days = Math.floor(diff/(1000*60*60*24))
        if (days == 0) {
            return `Hours ago`
        } else if (days <= 1) {
            return `${days} day ago`
        } else if (days < 7) {
            return `${days} days ago`
        } else if (days < 30) {
            let weeks = Math.round(days/7)
            if (weeks == 1) {
                return `${weeks} week ago`
            } else {
                return `${weeks} weeks ago`
            }
        } else if (days < 120) {
            let months = Math.round(days/30)
            if (months == 1) {
                return `${months} month ago`
            } else {
                return `${months} months ago`
            }
        } else if (days < 365) {
            return "Several months ago"
        } else {
            return "Over a year ago"
        }
    }
}
function getHour(h) {
    if (h>12) {
        return h -12
    } else {
        return h
    }
}
function getMinutes(m) {
    if (m<10) {
        return "0"+m
    } else {
        return m
    }
}
function getPeriod(h) {
    if (h>12) {
        return "PM"
    } else {
        return "AM"
    }
}
function mapDay(n) {
    switch (n) {
        case 0: return "Sunday"
        case 1: return "Monday"
        case 2: return "Tuesday"
        case 3: return "Wednesday"
        case 4: return "Thursday"
        case 5: return "Friday"
        case 6: return "Saturday"
        default: return "Error"
    }
}
function mapMonth(n) {
    switch (n) {
        case 0: return "January"
        case 1: return "February"
        case 2: return "March"
        case 3: return "April"
        case 4: return "May"
        case 5: return "June"
        case 6: return "July"
        case 7: return "August"
        case 8: return "September"
        case 9: return "October"
        case 10: return "November"
        case 11: return "December"
        default: return "Error"
    }
}